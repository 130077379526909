*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
  font-family: 'Avenir Next Codensed Regular' !important;
  font-size: 1.6rem !important;
  color: var(--main-font-color-dark) !important;
  font-weight: 300 !important;
}

body ::-webkit-scrollbar {
  display: none;
}

section {
  display: block;
  padding-top: 3.9rem;
  padding-bottom: 3.9rem;
  overflow: hidden;
}

a:not(.inverted):not(.btn-draw):hover {
  color:  var(--secondary-font-color) !important;
}

.ui.items a.item:hover .content .header, .ui.link.items>.item:hover .content .header {
  color: var(--secondary-font-color) !important;
}

.ui.items>.item>.content>a.header:hover {
  color: var(--secondary-font-color) !important;
}

.ui.progress {
  background: rgba(0,0,0,.02) !important;
}

.ui.progress .bar {
  background: #c39d63 !important;
}

.ui.headline {
  font-family: 'Avenir Next Codensed Regular' !important;
  font-weight: 300 !important;
  line-height: 1.28571429em !important;
  font-size: 3.5rem !important;
}

.ui.storename {
  font-family: 'Avenir Next Codensed Ultralight'   !important;
  font-weight: 300 !important;
  line-height: 1.28571429em !important;
  font-size: 3.5rem !important;
}

.secondary-font-color {
  color: var(--secondary-font-color) !IMPORTANT;
}

.dark-bg {
  background: #ecddc9;
  z-index: -1;
}

.ui.input.focus>input, .ui.input>input:focus { 
  border-color: var(--secondary-font-color) !important;
}


.ui.header.menuCard {
  color: var(--secondary-font-color) !important;
}
.ui.header.menuCardItem {
  color: rgba(255,255,255,.9) !important;
}

.ui.button {
  font-family: 'Avenir Next Codensed Ultralight' !important;
  font-weight: 300 !important;
}

.ui.menu {
  font-family: 'Avenir Next Codensed Regular' !important;
  font-weight: 600 !important;
  line-height: 1.28571429em !important;
  font-size: 1.6rem !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: var(--color-focus) !important;
  color: var(--color-focus) !important;
  font-weight: 300 !important;
}

.ui.lg-brown.label, .ui.lg-brown.labels .label {
  background-color: #c39d63!important;
  border-color: #c39d63!important;
  color: #fff!important;
}

@media screen and (max-width: 900px) {
  body {
    font-size: 1.3rem !important;
    font-weight: 300 !important;
  }
  .scroll-enabled {
    overflow-x: scroll;
    justify-content: initial !important;
  }
}

:root{
    --main-font: 'Avenir Next Codensed Regular';
    --secondary-font:'Avenir Next Codensed Ultralight';
    --body-font: 'Avenir Next Codensed Regular';
    --main-font-color-dark: #342714;
    --secondary-font-color: #BC995D;
    --color-focus:  #BC995D;
    --body-font-color: #342714;
    --main-font-color-error: #7F1019;
    --base-duration: 1.5s;
}