.whatsapp_float {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 15px;
  z-index: 90;
  transition:all .5s ease-in-out;
}

.whatsapp_float:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.whatsapp-icon {
  margin: 0 !important;
  margin-top: 17px !important;
}

.whatsapp-icon:hover {
  cursor: pointer;
  color: #000;
}

@media screen and (max-width: 900px) {
  .whatsapp-icon {
    margin: 0 !important;
    margin-top: 10px !important;
  }

  .whatsapp_float {
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}