.headline-bag {
  font-family: "Juri Handwriting" !important;
  font-weight: 100 !important;
}

.headline-regular {
  font-family: 'Avenir Next Codensed Ultralight' !important;
  font-weight: 300 !important;
}

h1.headline-regular {
  font-size: 4rem !important;
}

h2.headline-regular {
  font-size: 2rem !important;
}

h3.headline-bag {
  font-size: 1.9rem !important;
}

@media screen and (max-width: 900px) {
  h1.headline-regular {
    font-size: 3rem !important;
  }
  
  h2.headline-regular {
    font-size: 1rem !important;
  }
  
  h3.headline-bag {
    font-size: 1.8rem !important;
  }
}

@keyframes flyin {
  to { 
    -webkit-filter: blur(0);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes pulsing {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(188, 153, 93, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulsing {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(188, 153, 93, 0.4);
    box-shadow: 0 0 0 0 rgba(188, 153, 93, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 40px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}