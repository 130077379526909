@font-face {
  font-family: 'Juri Handwriting';
  src:  url('juri.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Codensed Ultralight';
  src:  url('AvenirNextCondensed-UltraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Codensed Regular';
  src:  url('AvenirNextCondensed-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bradley Hand';
  src:  url('bradhitc.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Alfa Slab One';
  src:  url('AlfaSlabOne-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}