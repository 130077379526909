.btn-styled-icon {
  min-width: 150px;
  font-weight: 500;
  letter-spacing: .3rem;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 10px 13px 40px;
  border-radius: 2px;
  transition: background .35s cubic-bezier(.3, .86, .36, .95), color .35s cubic-bezier(.3, .86, .36, .95);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 50px 40px;
  transition: transform .2s;
  margin: 7px 0;
  text-align: left;
  cursor: pointer;
}

.btn-styled {
  cursor: context-menu;
  min-width: 150px;
  font-weight: 500;
  letter-spacing: 0.3rem;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 40px 13px 40px;
  border-radius: 2px;
  transition: background 0.35s cubic-bezier(0.3, 0.86, 0.36, 0.95), color 0.35s cubic-bezier(0.3, 0.86, 0.36, 0.95);
  transition: transform 0.2s;
  margin: 7px 0;
  text-align: left;
  cursor: pointer;
}

.btn-styled-icon:hover {
  background-repeat: no-repeat;
  background-position: left;
  transform: scale(1.1);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%), 0 6px 20px 0 rgb(0 0 0 / 8%);
}

.btn-styled:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%), 0 6px 20px 0 rgb(0 0 0 / 8%);
}

.icon-googlemap {
  background-image: url('./images/google-maps.svg');
}

.icon-phone {
  background-image: url('./images/icon-phone.svg');
}

.icon-mail {
  background-image: url('./images/icon-mail.svg');
}

.icon-open {
  background-image: linear-gradient(to right bottom, #d4a847, #deab4d, #e7ae53, #f1b05a, #fab361);
}

.spanish-white {
  color: #c39d63;
  border: #ecddc9 1px solid;
  background-repeat: no-repeat;
  background-position: left;
}

@mixin btn-draw($color, $color-hov, $width: 1px, $padding: 0.5em, $time: 0.2s) {
  position: relative;

  display: inline-block;

  color: $color;
  border-bottom: $width solid $color;
  cursor: pointer;
  overflow: hidden;
  transition: color $time ease-in-out, background-color $time ease-in-out;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;

    height: 100%;
    width: $width;

    background: $color;
    transform: translateY(100%);
    transition: transform $time ease-in-out;
    transition-delay: $time * 3;
  }

  > span {
    position: relative;
    text-align: center;
    display: block;
    padding: $padding;

    color: inherit;

    &:before, 
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;

      background: $color;
      transition: transform $time ease-in-out;
    }

    &:before {
      height: $width;
      width: 100%;
      
      transform: translateX(100%);
      transition-delay: $time * 2;
    }

    &:after {
      height: 100%;
      width: $width;

      transform: translateY(-100%);
      transition-delay: $time;
    }
  }

  &:hover:after, 
  &:hover > span:before, 
  &:hover > span:after {
    transform: translate(0, 0);
  }

  &:hover:after {
    transition-delay: 0s;
  }

  &:hover > span:before {
    transition-delay: $time;
  }

  &:hover > span:after {
    transition-delay: $time * 2;
  }

  &:hover {
    color: $color-hov;
    background-color: $color;
    transition-delay: $time * 3;
  }
}

// Color variables
$color-blue: #c39d63;
$color-grey: #ecddc9;

.btn-draw {
  @include btn-draw($color-blue, $color-grey, 2px);
}