.global-header {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 98;
  background-image: linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,1) 48%, transparent 100%);
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.header-nav {
  -webkit-transition: all .3875s linear;
  -moz-transition: all .3875s linear;
  -o-transition: all .3875s linear;
  transition: all .3875s linear;
  padding-left: 20px;
}

.header-text-left {
  text-align: left !important;
  padding: 0 3.2rem !important;
  justify-content: space-between;
  align-items: center;
  height: 5.6rem;
  transition: background .2s ease-in-out;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: -.018rem;
}

.header .logo:hover {
  cursor: pointer;
}