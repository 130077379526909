
.article {
  padding: 30px 0
}
.article.horizontal {
  display: flex;
  flex-direction: column
}
.article.horizontal .article__img, .article.horizontal .article__txt {
  padding-bottom: 36px
}
.article.horizontal .article__img {
  line-height: 1;
  flex-shrink: 0;
  width: 100%;
  position: relative
}
.article.horizontal .article__img img {
  width: 100%;
  height: auto
}
.article.horizontal .article__img .img-stamp {
  right: 40px;
  bottom: 0
}
.article.horizontal .article__txt {
  align-self: flex-end;
  padding: 5px
}
.article.txt-first .article__txt {
  padding-bottom: 10px
}
.article.txt-first .article__img .img-stamp {
  bottom: -6%
}
.article.txt-first p {
  margin-bottom: 2px
}
.article.img-first .article__img .img-stamp {
  bottom: -7%
}
.article p {
  margin-bottom: 10px
}
.article .article__subheading {
  text-transform: uppercase;
  display: block;
  margin-bottom: 11px;
  letter-spacing: .24rem
}
.article.vertical {
  max-width: 1050px;
  margin: 0 auto
}
.article.vertical .article__img {
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: 50% 20%;
  margin-bottom: 46px
}
.article.vertical .article__txt {
  max-width: 745px;
  margin: 0 auto
}
.article.vertical .h1, .article.vertical h1 {
  font-size: 30px;
  line-height: 1.2;
  display: block;
  margin-bottom: 10px
}
.article .article__img .img {
  width: 100vw;
  height: 100%;
  min-height: 250px;
  background-size: cover;
  background-position: 50% 20%;
  margin: 0 -20px
}
.article .article__img.overlay {
  position: relative
}
.article .article__img.absolute {
  position: relative;
  z-index: 2;
  transform: translate(10%, -20%);
}
.article .article__img.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  background: linear-gradient(90deg, rgba(188, 153, 93, .6), rgba(188, 153, 93, 0));
  z-index: 9
}
.article .article__img.overlay-full .img {
  position: relative
}
.article .article__img.overlay-full .img:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(52, 39, 20, .2);
  z-index: 1
}
.article .article__img.overlay-bottom .img {
  position: relative
}
.article .article__img.overlay-bottom .img:before {
  content: "";
  position: absolute;
  top: 45%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(100, 79, 45, 0), #342714);
  z-index: 1
}
.about .article {
  padding: 22px 0 12px
}

.img-stamp {
  position: absolute;
  z-index: 9;
  transition: all .5s ease;
}

@media screen and (min-width:768px) {
  .article.vertical .article__img {
    height: 350px;
    margin-bottom: 100px
  }
  .article .article__img .img {
    min-height: 400px
  }
}
@media screen and (max-width: 900px) {
  .img-stamp {
    max-width: 180px;
  }
}
@media screen and (min-width:1024px) {
  .article {
    padding: 50px 0
  }
  .article.horizontal {
    flex-direction: row
  }
  .article.horizontal .article__img, .article.horizontal .article__txt {
    padding-bottom: 110px
  }
  .article.horizontal .article__img {
    width: 57.8%
  }
  .article.txt-first .article__txt {
    padding-bottom: 130px
  }
  .article.txt-first .article__img {
    margin-left: 30px
  }
  .article .article__img.absolute {
    position: absolute;
    transform: translate(0%, 0%);
  }
  .article.txt-first .article__img .img-stamp {
    left: 52px;
    bottom: 61px
  }
  .article.txt-first p {
    margin-bottom: 28px
  }
  .article.img-first .article__img {
    order: -1;
    margin-right: 30px
  }
  .article.img-first .article__img .img-stamp {
    right: 55px;
    bottom: 8px
  }
  .article p {
    margin-bottom: 28px
  }
  .article .article__subheading {
    letter-spacing: .3rem;
    margin-bottom: 10px
  }
  .article.vertical .article__img {
    height: 561px
  }
  .article.vertical .h1, .article.vertical h1 {
    margin-bottom: 8px;
    font-size: 60px;
    line-height: 1.1
  }
  .article .article__img .img {
    min-height: 611px;
    margin: 0;
    width: 100%
  }
  .about .article {
    padding: 20px 0 40px 0;
  }
  .article.txt-first .article__txt {
    padding-left: 113px
  }
  .article.txt-first .article__img {
    margin-left: 105px
  }
  .article.img-first .article__img {
    margin-right: 64px
  }
}