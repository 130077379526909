footer {
  padding: 3.9rem 0;
  background: url("../../images/black.jpg");
  background-size: cover;
  color: #fff;
  text-align: center;
  position: relative;
}

.footer-links {
  color: #c39d63;
}

.footer-navigation {
  color: #c39d63 !important;
}

.footer-links:hover {
  cursor: pointer;
  color: #fab361;
}

.footer-sozial {
  cursor: pointer;
  color: #c39d63;
}

.footer-sozial:hover {
  cursor: pointer;
  color: #fab361;
}

.footer-content {
  overflow: hidden;
}

.footer-content h4 {
  font-size: 1.9rem;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 3px;
  margin-bottom: 3rem;
}