#hamburger {
  z-index:5;
  left:calc(100% - 55px);
  width:30px;
  top: 55px
}
#buttonname{font-size:0}.c-hamburger{display:block;position:relative;overflow:hidden;margin:0;padding:0;width:30px;height:28px;border:none;cursor:pointer;transition:background .3s}
.c-hamburger:focus{outline:0}
.c-hamburger span{display:block;position:absolute;top:12px;left:0;right:0;height:4px;background:#BC995D}
.c-hamburger span::after,.c-hamburger span::before{position:absolute;display:block;left:0;width:100%;height:4px;background-color:#BC995D;content:""}
.c-hamburger--htx,.c-hamburger--htx.is-active{background-color:transparent}.c-hamburger span::before{top:-10px}
.c-hamburger span::after{bottom:-10px}.c-hamburger--htx span{transition:background 0s .3s}
.c-hamburger--htx span::after,.c-hamburger--htx span::before{transition-duration:.3s,.3s;transition-delay:.3s,0s}
.c-hamburger--htx span::before{transition-property:top,transform}
.c-hamburger--htx span::after{transition-property:bottom,transform}
.c-hamburger--htx.is-active span{background:0 0}
.c-hamburger--htx.is-active span::before{top:0;transform:rotate(45deg);background:rgba(0,0,0,.9)}
.c-hamburger--htx.is-active span::after{bottom:0;transform:rotate(-45deg);background:rgba(0,0,0,.9)}
.c-hamburger--htx.is-active span::after,.c-hamburger--htx.is-active span::before{transition-delay:0s,.3s}