.maps-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35px;
  height: 35px;
  background: url("images/marker.png") no-repeat;
  background-size: contain;
  user-select: none;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
}

.maps-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: url("images/marker.png") no-repeat;
  background-size: contain;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-fill-mode: both;
  animation-duration: 1s;
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: transparent;
    position: absolute;
    border-radius: 50%;
  }
}
.maps-pulse {
  background: #bc995d;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
  &:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    box-shadow: 0 0 1px 2px #bc995d;
    animation-delay: 1.1s;
  }
}
@keyframes pulsate {
  0%{
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50%{
    opacity: 1.0;
  }
  100%{
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0%{
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60%{
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80%{
    transform: translateY(-10px) rotate(-45deg);
  }
  100%{
    transform: translateY(0) rotate(-45deg);
  }
}